import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import { useJobs } from '../../hooks/useJobs'
import './JobApplicationForm.css'

const JobApplicationForm = ({ job }) => {
  const { error, sendEmail } = useJobs()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    cv: null,
    coverLetter: null,
  })
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    cv: null,
    coverLetter: null,
  })

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    setFormData({ ...formData, [event.target.name]: file })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateForm()) return

    const res = await sendEmail(formData, job)

    if (!res.ok) return

    setTimeout(() => {
      navigate('#jobs')
    }, 2000)

    // Reset the form
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      cv: '',
      coverLetter: '',
    })
    setFormErrors({
      fullName: '',
      email: '',
      mobileNumber: '',
      cv: '',
      coverLetter: '',
    })
  }

  const validateForm = () => {
    const errors = {}
    let isValid = true

    if (!formData.firstName.trim()) {
      errors.firstName = 'Fornavn is required'
      isValid = false
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Etternavn is required'
      isValid = false
    }

    if (!formData.email.trim()) {
      errors.email = 'E-post is required'
      isValid = false
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'E-post address is invalid'
      isValid = false
    }

    if (!formData.mobileNumber.trim()) {
      errors.mobileNumber = 'Mobilnummer is required'
      isValid = false
    } else if (!/^[0-9]*$/.test(formData.mobileNumber)) {
      errors.mobileNumber = 'Mobilnummer is invalid'
      isValid = false
    }

    if (formData.cv && !isFileTypeAllowed(formData.cv)) {
      errors.cv = 'Ugyldig filtype'
      isValid = false
    }
    if (formData.cv && formData.cv.size > 5 * 1024 * 1024) {
      errors.cv = 'Maks filstørrelse er 5MB'
      isValid = false
    }

    if (formData.coverLetter && !isFileTypeAllowed(formData.coverLetter)) {
      errors.coverLetter = 'Ugyldig filtype'
      isValid = false
    }
    if (formData.coverLetter && formData.coverLetter.size > 5 * 1024 * 1024) {
      errors.coverLetter = 'Maks filstørrelse er 5MB'
      isValid = false
    }

    setFormErrors(errors)
    return isValid
  }

  const isFileTypeAllowed = (file) => {
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/bmp',
    ]
    return allowedTypes.includes(file.type)
  }

  return (
    <form className="job-application-form" onSubmit={handleSubmit}>
      {error && (
        <span className="form-error" style={{ textAlign: 'center', fontSize: '14px' }}>
          {error}
        </span>
      )}
      <h2>Søk på stillingen</h2>
      <div className="form-group">
        <input type="hidden" name="email" value="bakige@gmail.com" />
        <input type="hidden" name="message" />

        <div className="error-group">
          <label htmlFor="first-name">Fornavn*</label>
          {formErrors.firstName && <span className="form-error">{formErrors.firstName}</span>}
        </div>
        <input
          type="text"
          id="first-name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <div className="error-group">
          <label htmlFor="last-name">Etternavn*</label>
          {formErrors.lastName && <span className="form-error">{formErrors.lastName}</span>}
        </div>
        <input
          type="text"
          id="last-name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <div className="error-group">
          <label htmlFor="mobile-number">Mobilnummer*</label>
          {formErrors.mobileNumber && <span className="form-error">{formErrors.mobileNumber}</span>}
        </div>
        <input
          type="tel"
          id="mobile-number"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <div className="error-group">
          <label htmlFor="email">E-post*</label>
          {formErrors.email && <span className="form-error">{formErrors.email}</span>}
        </div>
        <input type="text" id="email" name="email" value={formData.email} onChange={handleChange} />
      </div>
      <div className="action-buttons">
        <div className="form-group">
          <label htmlFor="cv">Velg CV</label>
          <input
            type="file"
            id="cv"
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .bmp"
            name="cv"
            onChange={handleFileInputChange}
          />
          {formErrors.cv && <span className="form-error">{formErrors.cv}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="coverLetter">Velg Søknad</label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .bmp"
            id="coverLetter"
            name="coverLetter"
            onChange={handleFileInputChange}
          />
          {formErrors.coverLetter && <span className="form-error">{formErrors.coverLetter}</span>}
        </div>
      </div>
      <div className="action-buttons-apply">
        <button type="submit">Send inn</button>
        <button type="button" onClick={() => navigate('#jobs')}>
          Tilbake
        </button>
      </div>
    </form>
  )
}

export default JobApplicationForm
