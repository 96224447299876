import React, { useState } from 'react'
import MyPageLayout from '../layouts/my-page-layout'
import { PrimaryButton } from '../components/styled/buttons'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import JobApplicationForm from '../components/jobs/JobApplicationForm'
import JobVacancyList from '../components/jobs/JobVacancyList'

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  padding-top: 0rem;
  h2 {
    margin-top: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
`

const Buttons = styled.div`
  width: 220px;
  margin-top: 2rem;
  button {
    padding: 0.7rem;
    cursor: pointer;
  }
`

const JobVacancy = ({ location }) => {
  const [job, setCurrentJob] = useState({})
  const hash = location?.hash

  const _goToApplicationForm = (data) => {
    setCurrentJob(data)
    navigate('#job-apply')
  }

  return (
    <MyPageLayout title="JOBBE HOS OSS">
      <Container>
        {hash === '' && !hash.includes(['#jobs', '#job-list', '#job-apply']) && (
          <div>
            <h2>Jobb hos oss i Bilxtra</h2>
            <p>
              Bilxtra er en landsdekkende kjede bestående av 260 verksteder i Norge. I tillegg
              består Bilxtra av 60 grossister og butikker. Vi kurser og har samarbeidspartnere på
              det meste av det som trengs i bransjen.
            </p>
            <p>
              I Bilxtra brenner vi for å gjøre kundeopplevelsen optimal, og vi strekker oss langt
              for å bygge et godt navn rundt vårt varemerke Bilxtra.
            </p>
            <p>
              For å lykkes med dette trenger vi stadig nye, engasjerte kollegaer på ulike deler av
              landet inn i temaet vårt. Hos oss er medarbeiderne vår viktigste ressurs, og hver
              enkelt av oss er avgjørende for å oppnå Bilxtra sine gode ambisjoner.
            </p>
            <Buttons>
              <PrimaryButton type="button" onClick={() => navigate('#jobs')}>
                Se ledige stillinger
              </PrimaryButton>
            </Buttons>
          </div>
        )}
        {hash === '#jobs' && <JobVacancyList goToApplicationForm={_goToApplicationForm} />}
        {hash === '#job-apply' && <JobApplicationForm job={job} />}
      </Container>
    </MyPageLayout>
  )
}

export default JobVacancy
