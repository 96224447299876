import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useJobs } from '../../hooks/useJobs'

import elbilLogo from '../../images/EL_BIL merke.png'

import './JobVacancyList.css'
import JobSingle from './JobSingle'

const JobVacancyList = ({ goToApplicationForm }) => {
  const { loading, jobs, getJobsList } = useJobs()
  const [singleJob, setSingleJob] = useState(null)

  useEffect(() => {
    getJobsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const truncateText = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str
  }

  const _setSingle = () => {
    setSingleJob(null)
  }

  const _goToApplicationForm = (job) => {
    goToApplicationForm(job)
  }
  return (
    <>
      {!singleJob ? (
        <div className="jobs">
          <h2 style={{ textAlign: 'center' }}>Hvorfor jobbe hos Bilxtra ?</h2>
          <div className="container">
            <div className="left-box">
              <img src={elbilLogo} alt="logo" />
            </div>
            <div className="middle-box">
              <div className="circle">
                <div className="tick-mark"></div>
              </div>
              <div className="circle">
                <div className="tick-mark"></div>
              </div>
              <div className="circle">
                <div className="tick-mark"></div>
              </div>
            </div>
            <div className="right-box">
              <div className="box">
                <h3>Engasjerte kollegaer</h3>
                <p>
                  Et morsomt arbeidsmiljø. Engasjert og bryr oss om jobben vi utfører. Opptatt av
                  kunden, støtter hverandre og utveksler kunnskap
                </p>
              </div>
              <div className="box">
                <h3>Spennende marked</h3>
                <p>
                  Å jobbe med bil er interessant og byr på utfordring, samtidig er det variert og
                  spennende
                </p>
              </div>
              <div className="box">
                <h3>Gode betingelser</h3>
                <p>Lønn etter avtale pensjons og personalforsikringer.</p>
              </div>
            </div>
          </div>
          {loading ? (
            <span>Loading...</span>
          ) : (
            <>
              {jobs.length === 0 && (
                <h4 style={{ textAlign: 'center' }}>Ingen ledige stillinger for øyeblikket</h4>
              )}
              {jobs.length > 0 && (
                <>
                  <div className="job-list-title">
                    <h3>Ledige stillinger</h3>
                  </div>
                  <div className="job-list">
                    {jobs.map((job) => (
                      <div key={job.id} className="job-list-item">
                        <div className="job-list-item-title">{job.title}</div>
                        <div className="job-list-item-title" aria-label="Workshop">
                          &#9873;&nbsp;{job.workshop}
                        </div>
                        <div className="job-list-item-createdate">
                          <span role="img" aria-label="Love">
                            &#x1F552;&nbsp;{moment(job.create_date).format('DD/MM/yyyy')}
                          </span>
                        </div>
                        <div
                          className="job-list-item-description"
                          dangerouslySetInnerHTML={{ __html: truncateText(job.description, 150) }}
                        ></div>
                        <div className="action-buttons">
                          <button
                            className="job-list-item-apply"
                            onClick={() => _goToApplicationForm(job)}
                          >
                            Søk her
                          </button>
                          <button onClick={() => setSingleJob(job)}>Se mer</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <JobSingle job={singleJob} setSingle={_setSingle} />
      )}
    </>
  )
}

export default JobVacancyList
