import React from 'react'
import { navigate } from 'gatsby-link'
import moment from 'moment'
import styled from 'styled-components'

const ApplyButton = styled.button`
  width: 8rem;
  padding: 0.6rem;
  color: white;
  font-size: inherit;
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  transition: background 0.25s ease-out;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 4rem 0 0 0;
  button:nth-child(2) {
    background: #fff;
    color: #333;
  }
`

const JobSingle = ({ job, setSingle }) => {
  return (
    <div>
      <h2>{job.title}</h2>
      <div aria-label="Workshop">
        &nbsp;&#9873;&nbsp;<strong>{job.workshop}</strong>
      </div>
      <span role="img" aria-label="Love">
        &#x1F552;{moment(job.create_date).format('DD/MM/yyyy')}
      </span>
      <br />
      <br />
      <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
      <ButtonContainer>
        <ApplyButton type="button" onClick={() => navigate('#job-apply')}>
          Søk her
        </ApplyButton>
        <ApplyButton onClick={setSingle}>Tilbake</ApplyButton>
      </ButtonContainer>
    </div>
  )
}

export default JobSingle
