import { useState } from 'react'

export const useJobs = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [jobs, setJobs] = useState([])

  const getJobsList = () => {
    setLoading(true)

    try {
      return fetch(`${process.env.API_URL}/jobs`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then(async (response) => {
        setLoading(false)

        if (!response.ok) setError('An error occurred')
        else {
          const data = await response.json()
          setJobs(data)
        }
      })
    } catch (ex) {
      console.log('ex', ex)
      setError('An error occurred')
    }
  }

  const sendEmail = async (data, job) => {
    setLoading(true)

    try {
      const payload = {
        to_email: job.email,
        job_title: job.title,
        full_name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        mobile_number: data.mobileNumber,
      }

      if (data.cv) {
        payload.cv = {
          file_name: data.cv.name,
          file: await toBase64(data.cv),
        }
      }

      if (data.coverLetter) {
        payload.coverLetter = {
          file_name: data.coverLetter.name,
          file: await toBase64(data.coverLetter),
        }
      }

      return fetch(`${process.env.API_URL}jobs/application`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }).then(async (response) => {
        setLoading(false)

        if (response.ok) {
          setError('Søknaden ble sendt.')
        } else {
          const error = await response.json()
          setError(`An error occurred ${error}`)
        }

        return response
      })
    } catch (ex) {
      console.log('ex', ex)
      setError(`An error occurred`)
      return ex
    }
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        const base64Data = reader.result.split(',')[1]
        resolve(base64Data)
      }

      reader.onerror = () => {
        reader.abort()
        reject(new Error('Error reading file.'))
      }

      reader.readAsDataURL(file)
    })

  return {
    loading,
    error,
    jobs,
    getJobsList,
    sendEmail,
  }
}
